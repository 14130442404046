import i18next from 'i18next';
import i18nextXHRBackend from 'i18next-xhr-backend';

const i18nextCore = (locale, baseUrl, cb) => {
  return i18next.use(i18nextXHRBackend).init(
    {
      lng: locale,
      fallbackLng: 'en',
      keySeparator: '$',
      interpolation: {
        escapeValue: false,
      },
      backend: {
        loadPath: `${baseUrl}assets/locales/platform_{{lng}}.json`,
        crossDomain: true,
      },
    },
    cb,
  );
};

export const i18n = ({
  locale,
  baseUrl,
}): Promise<{ t: any; i18nInstance: any }> => {
  return new Promise((resolve, reject) => {
    const i18nInstance = i18nextCore(locale, baseUrl, (err, t) =>
      err ? reject(err) : resolve({ t, i18nInstance }),
    );
  });
};
