import AppManifest, {
  CustomActionConfig,
  PageActionsEvent,
  PageSettingsTab,
} from './types/manifest';
import { Translations } from './Translations';
import { AppDescriptorAction } from './appDescriptor';

// https://bo.wix.com/wix-docs/client/client-frameworks#editor-platform.articles.pages-manifest
export function getGroupsManifest(
  translation: Translations,
  pageSettingsUrls: {},
  withAppDescriptor: boolean,
): AppManifest {
  const appDescriptor = withAppDescriptor
    ? {
        mainActions: [
          {
            title: translation.t('groups-web.manage.actions.main'),
            actionId: AppDescriptorAction.MAIN_ACTION,
            icon: 'appManager_settingsAction',
          } as CustomActionConfig,
        ],
        customActions: [
          {
            title: translation.t('groups-web.manage.actions.create.group'),
            actionId: AppDescriptorAction.CREATE_GROUP,
            icon: 'appManager_relatedAppsAction',
            type: 'editorActions',
          },
          {
            title: translation.t('groups-web.manage.actions.add.widget'),
            actionId: AppDescriptorAction.ADD_WIDGET,
            icon: 'appManager_addElementsAction',
            type: 'editorActions',
          },
          {
            title: translation.t('groups-web.manage.actions.setup'),
            actionId: AppDescriptorAction.SETUP,
            icon: 'appManager_settingsAction',
            type: 'editorActions',
          },
        ] as CustomActionConfig[],
        defaultActions: {
          learnMoreKB: '57cfed92-b0db-435e-8872-03b6637bd2e6',
        },
      }
    : undefined;
  return {
    pages: {
      pageActions: {
        default: [
          {
            type: 'Pages_Actions_Page_Rename',
          },
          {
            title: translation.t('groups-web.page.delete'),
            icon: 'delete_icon',
            event: PageActionsEvent.REMOVE,
            type: 'page_remove',
          },
        ],
        groupPage: [],
      },
      pageSettings: {
        default: [
          {
            title: translation.t('groups-web.page.info'),
            type: PageSettingsTab.PAGE_INFO,
          },
          {
            title: translation.t('groups-web.page.layout'),
            type: PageSettingsTab.LAYOUT,
          },
          {
            title: translation.t('groups-web.page.permissions'),
            type: PageSettingsTab.PERMISSIONS,
          },
          {
            title: translation.t('groups-web.page.seo'),
            type: PageSettingsTab.SEO,
          },
        ],
        groupPage: [
          {
            title: translation.t('groups-web.page.info'),
            type: PageSettingsTab.PAGE_INFO,
            url: pageSettingsUrls[PageSettingsTab.PAGE_INFO],
          },
          {
            title: translation.t('groups-web.page.layout'),
            type: PageSettingsTab.LAYOUT,
          },
          {
            title: translation.t('groups-web.page.permissions'),
            type: PageSettingsTab.PERMISSIONS,
          },
        ],
      },
      applicationSettings: {
        default: {
          displayName: 'Groups Pages',
          helpId: '',
        },
      },
      applicationActions: {
        default: [],
      },
    },
    appDescriptor,
  };
}
