import { withMembersArea, MA_APP_IDS } from '@wix/members-area-integration-kit';
import * as platformApp from './editor';

const platformAppWithMA = withMembersArea(platformApp, {
  disableADI: false,
  membersAreaApps: [
    MA_APP_IDS.ABOUT,
    MA_APP_IDS.NOTIFICATIONS,
    MA_APP_IDS.SETTINGS,
    MA_APP_IDS.ALL_MEMBERS,
  ],
});

export const editorReady = platformAppWithMA.editorReady;
export const onEvent = platformAppWithMA.onEvent;
export const getAppManifest = platformAppWithMA.getAppManifest;
export const handleAction = platformAppWithMA.handleAction;
